$theme-colors: (
  'primary': #0a53a8,
  'secondary': #fc3998,
  'yellow': #ffcb39,
  'charcoal': #36454F
);

body {
  font-family: Inter, sans-serif !important;
  font-feature-settings: 'liga' 1, 'calt' 1 !important; /* fix for Chrome */
  background-color: #ffffff;
}

body.dark {
  color: #ffffff;
  background-color: #181a1b;
}

h1 {
  border-top: 5px solid #0a53a8;
}

footer {
  color: black;
  border-top: 5px solid #fc3998;
  background-color: #ffcb39;
}

.footer-icon {
  vertical-align: middle !important;
}

.footer-icon:hover {
  fill: #fc3998;
}

.navbar {
    border-bottom: 5px solid #fc3998
}

.navbar-brand {
    margin-left: 10px;
}

.navbar-toggle {
  margin-right: 20px;
}

.nav-link {
  font-size: 20px !important;
}

.col {
  width: 90% !important;
}

.dropdown-menu-light {
  background-color: #ffffff !important;
}

.dropdown-item-light {
  color: black !important;
}

.about-lead {
  font-size: 25px !important
}

.about {
  font-size: 20px !important
}

@import '~bootstrap/scss/bootstrap';